import React from "react";

import logo from "../assets/logo.svg";

const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" />
    <h1 className="mb-4">Sample React Client</h1>

    <p className="lead">
      This is a sample application that demonstrates OAuth Authorization Code flow w/ PKCE extensions.
    </p>

    <h5>How to Use</h5>

    <p>Click the "Log In" button to authenticate, then click the &quot;View JWT&quot; tab to display the resulting JSON web token.</p>
  </div>
);

export default Hero;
